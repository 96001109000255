import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src3499616894/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { TableProps } from '../../components/TableProps';
export const name = 'LogTable';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  name,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{name}</h2>
    <TableProps columns={[{
      name: 'app.component',
      type: 'text'
    }, {
      name: 'app.correlationId',
      type: 'text'
    }, {
      name: 'app.instanceId',
      type: 'text'
    }, {
      name: 'app.requestId',
      type: 'text'
    }, {
      name: 'app.sessionId',
      type: 'text'
    }, {
      name: 'app.user',
      type: 'text'
    }, {
      name: 'app.requestAddressChain',
      type: 'text'
    }, {
      name: 'app.requestMethod',
      type: 'text'
    }, {
      name: 'app.requestOriginAddress',
      type: 'text'
    }, {
      name: 'app.requestPath',
      type: 'text'
    }, {
      name: 'app.requestProtocol',
      type: 'text'
    }, {
      name: 'app.requestQuery',
      type: 'text'
    }, {
      name: 'app.responseStatus',
      type: 'text'
    }, {
      name: 'app.responseTime',
      type: 'text'
    }, {
      name: 'app.authEven',
      type: 'text'
    }, {
      name: 'app.authMessage',
      type: 'text'
    }, {
      name: 'app.authPrincipal',
      type: 'text'
    }, {
      name: 'app.authStrategy',
      type: 'text'
    }, {
      name: 'k8s.container',
      type: 'text'
    }, {
      name: 'k8s.namespace',
      type: 'text'
    }, {
      name: 'k8s.nodeAddress',
      type: 'text'
    }, {
      name: 'k8s.nodeName',
      type: 'text'
    }, {
      name: 'k8s.pod',
      type: 'text'
    }, {
      name: 'level',
      type: 'text'
    }, {
      name: 'message',
      type: 'text'
    }, {
      name: 'timestamp',
      type: 'text'
    }]} mdxType="TableProps" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      